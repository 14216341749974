<template>
  <content justify="center">
    <v-row justify="center">
      <div></div>
    </v-row>

    <v-row align-content="center" class="justify-center">
      <v-col cols="12" sm="12" md="12" lg="12" xl="9">
    <v-card>
    <v-tabs
      v-model="tab"
      background-color="deep-purple accent-4"
      centered
      dark
      icons-and-text
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        Solicitar
        <v-icon>mdi-car</v-icon>
      </v-tab>

      <v-tab href="#tab-2">
        Info
        <v-icon>mdi-information</v-icon>
      </v-tab>

    </v-tabs>


    <v-tabs-items v-model="tab">
      <v-tab-item
        value="tab-1"
      >
        <v-card flat>
          <v-card-text>
            <v-data-table
          :headers="headers"
          :items="solicitudes"
          sort-by="date"
          class="elevation-1"
          :loading="cargandoSolicitudesSupply" 
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title
                >Solicitudes  de Supply para Bodegas</v-toolbar-title
              >
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn
                    color="primary"
                    dark
                    class="mb-2"                 
                 @click="dialogTable=true"
                  >
                    Nuevo ingreso de solicituds
                  </v-btn>
              <v-dialog v-model="dialogTable" max-width="900px">
                <template>
                
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <!-- <v-text-field
                      v-model="editedItem.name"
                      label="Dessert name"
                    ></v-text-field> -->
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                            
                                v-model="editedItem.date"
                                label="Selecciona fecha"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                       
                              v-model="editedItem.date"
                              :active-picker.sync="activePicker"
                            
                              min="2020,
        001-01"
                              @change="save"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <!-- <v-text-field
                      v-model="editedItem.calories"
                      label="Calories"
                    ></v-text-field> -->

                          <v-autocomplete
                            :items="turnos"
                       v-model.lazy="editedItem.turno"
                            @change="changeCheca"
                            value="id"
                            item-value="id"
                            return-object
                            
                            item-text="name"
                            label="Turno"
                            solo
                            :rules="bodegaRules"
                            :loading="cargandoTurnos"
                            required
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-autocomplete
                            :items="tiposVehiculos"
                            item-value="id"
                            @change="changeCheca"
                            v-model="editedItem.tipoVehiculo"
                            return-object
                            item-text="name"
                            label="Tipo Vehículo"
                            solo
                            :rules="bodegaRules"
                            :loading="cargandoTiposVehiculos"
                            required
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                          type="number" 
                          @change="changeCheca"
                            v-model="editedItem.cantidad"
                            label="Cantidad"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-autocomplete
                            :items="bodegas"
                            item-value="id"
                            return-object
                            @change="changeCheca"
                            v-model="editedItem.bodega"
                            item-text="nombre_bodega"
                            label="Bodega"
                            solo
                            :rules="bodegaRules"
                            :loading="cargandoBodegas"
                            required
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
    <v-switch
    :disabled ="editedIndex===-1"
  
      v-model="editedItem.confirmado"
      :label="editedItem.confirmado?'Confirmado':'No Confirmado'"
    ></v-switch>   </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn :disabled=!allFields color="blue darken-1" text @click="saveTable">
                      Guardar
                    </v-btn>
                    <v-progress-circular
                    v-if="saving"
      indeterminate
      color="primary"
    ></v-progress-circular>
                  </v-card-actions>
                  
                </v-card>
               
              </v-dialog>
              <v-dialog v-model="dialogoGenerico" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">ATENCIÓN</v-card-title>
                  <v-card-text> {{ textoDialogoGenerico }}</v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogoGenerico = false"
                      >OK</v-btn
                    >

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this item?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="obtenerSolicitudesSupply">
              Recargar
            </v-btn>
          </template>
        </v-data-table>

          </v-card-text>
        </v-card>
      </v-tab-item>


      <v-tab-item
        value="tab-2"
      >
        <v-card flat>
          <v-card-text>
            <v-row cols="12">
      <!-- <iframe
        src="https://charts.mongodb.com/charts-fleet-manager-rltbl/public/dashboards/5147d233-9671-42b1-b7d5-551f040265d1"
        title="Dashboard"
      ></iframe> -->
      <iframe style="background: #F1F5F4;border: none;border-radius: 2px;box-shadow: 0 2px 10px 0 rgba(70, 76, 79, .2);"  src="https://charts.mongodb.com/charts-fleet-manager-rltbl/embed/dashboards?id=8815d1db-f94b-4dd3-8f04-d71777cb06fd&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=false&scalingWidth=fixed&scalingHeight=fixed"></iframe>
    </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>


    </v-tabs-items>


        </v-card>
    
      </v-col>
    </v-row>
  </content>
</template>

<script>
import axios from "axios";
import { json } from "body-parser";
import firebase from "firebase";
import Vue from "vue";

export default {
  data() {
    return {
      
      dialogTable:false, 
      tab: null,
      saving: false,
      cargandoSolicitudesSupply: false,
      solicitudesSupply: [],
      textoDialogoGenerico: "",
      dialogoGenerico: false,
      dialogDelete: false,
      headers: [
        {
          text: "id",
          align: "start",
          sortable: true,
          value: "_id",
        },
        {
          text: "Bodega Rayo",
          align: "start",
          sortable: true,
          value: "bodega.nombre_bodega",
        },
        { text: "Fecha", value: "date", sortable: true },
        { text: "Turno", value: "turno.name", sortable: true },
        { text: "Cantidad", value: "cantidad", sortable: true },
        {
          text: "Tipo Vehículo",
          value: "tipoVehiculo.name",
          sortable: true,
        },
        { text: "Solicitante", value: "emailSolicitante", sortable: true },
        { text: "Confirmado", value: "confirmado", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
      solicitudes: [],
      allFields:false,
      editedIndex: -1,
      baseEditedItem: {emailSolicitante:null, confirmado: false, turno:{name:null,id:null},date:null,bodega:{nombre_bodega:null,id:null},cantidad:null,tipoVehiculo:{name:null,id:null}},
      editedItem: {emailSolicitante:null,confirmado: false,turno:{name:null,id:null},date:null,bodega:{nombre_bodega:null,id:null},cantidad:null,tipoVehiculo:{name:null,id:null}},
      defaultItem: {},
      tipoVehiculoSeleccionado: null,
      turnoSeleccionado: null,
      turnos: [],
      cargandoTurnos: false,
      bodegas: [],
      cargandoTiposVehiculos: false,
      tiposVehiculos: [],
      cargandoBodegas: false,
      arregloBodegas: [],
      activePicker: null,
      menu: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      btnSacarRuta: true,
      posicionesRules: [(v) => !!v || "Debes seleccionar posicion"],
      posiciones: [],
      totalPedidos: null,
      posicionSeleccionada: "",
      cargandoPosiciones: false,
      rayoRules: [(v) => !!v || "Debes seleccionar"],
      bodegaRules: [(v) => !!v || "Debes seleccionar"],
      pedidosRules: [(v) => !!v || "Debes ingresar al menos un pedido"],
      listaRayos: [],
      rayoSeleccionado: "",
      // bodega: "637ba18c37ca38e567e32968",
      listaPedidos: "",
      dialogoRespuestaSalida: false,
      colorProgreso: "",
      validar: false,
      cargandoListaRayos: false,
      fechaActual: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      itemsFallidos: [],
      resultadoSalidas: [],
      resultadoInventory: [],
      arrayRutaValidada: null,
      estados: [
        {
          _id: "5f05f3f77f5164494c74b96a",
          number_status: 5,
          name_status: "En progreso",
        },
        {
          _id: "5f05f3dc7f5164494c74b968",
          number_status: 3,
          name_status: "Aceptado",
        },
        {
          _id: "5f05f3d37f5164494c74b967",
          number_status: 2,
          name_status: "Asignado",
        },
        {
          _id: "5f05f4217f5164494c74b96d",
          number_status: 8,
          name_status: "Rechazado",
        },
        {
          _id: "5f05f3607f5164494c74b966",
          number_status: 1,
          name_status: "No asignado",
        },
        {
          _id: "5f05f4147f5164494c74b96c",
          number_status: 7,
          name_status: "Fallido",
        },
        {
          _id: "5f05f3eb7f5164494c74b969",
          number_status: 4,
          name_status: "Iniciado",
        },
        {
          _id: "5f05f4077f5164494c74b96b",
          number_status: 6,
          name_status: "Exitoso",
        },
        {
          _id: "5f05f42e7f5164494c74b96e",
          number_status: 9,
          name_status: "Cancelado",
        },
        {
          _id: "60711a2e476d98a0944ca9f0",
          name_statu: "Silent Arrival",
          number_status: 20,
        },
        {
          _id: "60bfdb431b02612234560b16",
          number_status: 30,
          name_status: "Devuelto",
          __v: 0,
        },
      ],
    };
  },
  watch: {
    dialogTable(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
      computed: {       
    formTitle() {
      return this.editedIndex === -1
        ? "Nueva Solicitud de Supply"
        : "Editar Solicitud ";
    },
    // async obtenerRayos() {
    //   try {
    //     // console.log("Obteniendo Rayos...");
    //     this.cargandoListaRayos = true;
    //     let arrayFleets = [];
    //     const listaRayos = await axios.get(
    //       "https://southamerica-east1-thor-fm.cloudfunctions.net/Rayos/Rayos/rayos/reduced"
    //     );

    //     for (const n in listaRayos.data) {
    //       arrayFleets.push({
    //         text: listaRayos.data[n].name_rayo,
    //         id: listaRayos.data[n].id,
    //       });
    //     }

    //     console.log(listaRayos.status);

    //     listaRayos.status == 200
    //       ? console.log("Lista creada!")
    //       : console.log("No se logró obtener la lista! Revisar API");

    //     arrayFleets.sort((b, a) => b.text.localeCompare(a.text));
    //     this.listaRayos = arrayFleets;

    //     this.cargandoListaRayos = false;
    //   } catch (error) {
    //     this.cargandoListaRayos = false;
    //     console.log(error);
    //   }
    // },
    // async obtenerPosiciones() {
    //   try {
    //     console.log("Obteniendo posiciones...");
    //     this.cargandoPosiciones = true;
    //     let arregloPosiciones = [];

    //     const posiciones = await axios.get(
    //       "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/posiciones"
    //     );
    //     console.log(posiciones.status);

    //     for (const n in posiciones.data) {
    //       arregloPosiciones.push({
    //         nombre_posicion: posiciones.data[n].name_position,
    //         id: posiciones.data[n]._id,
    //       });
    //     }
    //     // console.log(arregloPosiciones);
    //     this.posiciones = arregloPosiciones;
    //     console.log("Lista creada!");

    //     this.cargandoPosiciones = false;
    //   } catch (error) {
    //     this.cargandoPosiciones = false;
    //     console.log(error);
    //   }
    // },
 
  },
  mounted() {     
    
      // this.obtenerRayos;
      // this.obtenerPosiciones;
       this.obtenerBodegas();
       this.obtenerSolicitudesSupply();
       this.obtenerTurnos();
       this.obtenerTiposVehiculos();
       this.datosUsuario();
    },
  methods: {
  
    async obtenerBodegas() {
      try {
        console.log("Obteniendo bodegas...");
        this.cargandoBodegas = true;
        let arregloBodegas = [];

        const bodegas = await axios.get(
          "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/bodegas"
        );
        console.log(bodegas.status);

        for (const n in bodegas.data) {
          arregloBodegas.push({
            nombre_bodega: bodegas.data[n].name_wh,
            id: bodegas.data[n]._id,
          });
        }
        // console.log(arregloPosiciones);
        this.bodegas = arregloBodegas;
        console.log("Lista creada!");

        this.cargandoBodegas = false;
      } catch (error) {
        this.cargandoBodegas = false;
        console.log(error);
      }
    },
    async obtenerTiposVehiculos() {
      try {
        console.log("Obteniendo tipos vehículos...");
        this.cargandoTiposVehiculos = true;
        let arregloTiposVehiculos = [];

        const tiposVehiculos = await axios.get(
          "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/tiposVehiculos"
        );
        console.log(tiposVehiculos.status);

        for (const n in tiposVehiculos.data) {
          arregloTiposVehiculos.push({
            name: tiposVehiculos.data[n].vehicleTypeName,
            id: tiposVehiculos.data[n]._id,
          });
        }
        // console.log(arregloPosiciones);
        this.tiposVehiculos = arregloTiposVehiculos;
        console.log("Lista creada tipos de vehiculos!");

        this.cargandoTiposVehiculos = false;
      } catch (error) {
        this.cargandoTiposVehiculos = false;
        console.log(error);
      }
    },
    async obtenerSolicitudesSupply() {
      try {
        console.log("Obteniendo solicitudes...");
        this.cargandoSolicitudesSupply = true;
        let arregloSolicitudesSupply = [];

        const solicitudesSupplyIn = await axios.get(
          "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/solicitudesSupply"
        );
        console.log(solicitudesSupplyIn.status);

        for (const n in solicitudesSupplyIn.data) {
          arregloSolicitudesSupply.push(solicitudesSupplyIn.data[n]);
        }
        // console.log(arregloPosiciones);
        this.solicitudes = arregloSolicitudesSupply;
        console.log("Lista creada solicitudes supply!");

        this.cargandoSolicitudesSupply = false;
      } catch (error) {
        this.cargandoSolicitudesSupply = false;
        console.log(error);
      }
    },
    async obtenerTurnos() {
      try {
        console.log("Obteniendo turnos...");
        this.cargandoTurnos = true;
        let arregloTurnos = [];

        const turnos = await axios.get(
          "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/obtenerTurnos"
        );
        console.log(turnos.status);

        for (const n in turnos.data) {
          arregloTurnos.push({
            name: turnos.data[n].name,
            id: turnos.data[n]._id,
          });
        }
        // console.log(arregloPosiciones);
        this.turnos = arregloTurnos;
        console.log("Lista creada tipos de turnos!");

        this.cargandoTurnos = false;
      } catch (error) {
        this.cargandoTurnos = false;
        console.log(error);
      }
    },
    checaForm() {
      
      if (this.editedItem.cantidad !== null && this.editedItem.cantidad !== undefined && this.editedItem.cantidad !== '') {
        this.editedItem.cantidad = parseInt(this.editedItem.cantidad)
      }
    
      if( 
          ( this.editedItem.confirmado !== null && this.editedItem.bodega.id  !== null && this.editedItem.turno.id  !== null && this.editedItem.tipoVehiculo.id  !== null   && this.editedItem.date  !== null  && this.editedItem.cantidad !== null  ) 
         &&
          ( this.editedItem.confirmado  !== undefined && this.editedItem.bodega.id   !== undefined && this.editedItem.turno.id   !== undefined && this.editedItem.tipoVehiculo.id    !== undefined && this.editedItem.date   !== undefined && this.editedItem.cantidad   !== undefined )
          &&  this.editedItem.cantidad  !== ''  
       ) {
        this.allFields = true
       } else {
        this.allFields = false
       }
    },
    changeCheca(ec) {
      console.log(ec);
      // this.editedItem.bodega = ec.nombre_bodega;
      this.checaForm()
      // this.editedItem.bodegaSeleccionadaId = ec.id
    },
 
    // changeBodega(ec) {
    //   console.log(ec);
    //   // this.editedItem.bodega = ec.nombre_bodega;
    //   this.checaForm()
    //   // this.editedItem.bodegaSeleccionadaId = ec.id
    // },
    // changeTurno(ec) {
    //   console.log(ec);
    //   // this.editedItem.turno = ec.name;
    //   this.checaForm()
    //   // this.editedItem.turnoId = ec.id
    // },
    // changeTipoVehiculo(ec) {
    //   console.log(ec);
    //   // this.editedItem.tipoVehiculo = ec.name;
    //   this.checaForm()      
    // },
    saveTable() {
      
  
      const that = this;
      let itemEvaluado = that.editedItem;
   

      if(that.editedIndex === -1) {
        that.editedItem.emailSolicitante = that.datosUsuario().email_user;
      }
      //  else {
      //   that.editedItem.emailSolicitante = that.editedItem.emailSolicitante;
      // }

      that.saving = true
        axios
          .post(
            "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/creaSolicitudSupply",
            that.editedItem
          )
          .then(function (response) {
            console.log(response);
          
         

            if (that.editedIndex > -1) {
              // edit local
              Object.assign(that.solicitudes[that.editedIndex], that.editedItem);
              console.log(that.solicitudes[that.editedIndex])
              console.log(that.editedItem)
              console.log(that.editedItem === that.solicitudes[that.editedIndex] )
              that.close();
              // that.solicitudes.push(itemEvaluado);
            } else {
                 // push local
            that.solicitudes.push(itemEvaluado);
            that.editedItem = JSON.parse(JSON.stringify(that.baseEditedItem))
            that.close();
            }
            that.saving = false
            that.textoDialogoGenerico =
            "Registro o modificación exitosa"
            that.dialogoGenerico = true;


//dialogo exito
            

          })
          .catch(function (error) {
            that.saving = false
            //dialogo fallo
            that.dialogoGenerico = true;
            console.log(error);
            that.textoDialogoGenerico = error.data.message;
          });
   
   
    },
    editItem(item) {
      console.log(item)
     
        this.editedItem = JSON.parse(JSON.stringify(this.baseEditedItem))
        // this.dialogTable = true;
        const that = this
       


          console.log(item)
          that.editedIndex = that.solicitudes.indexOf(item);
          that.editedItem._id = item._id
          that.editedItem.turno.name=item.turno.name
          that.editedItem.turno.id=item.turno.id
          that.editedItem.date = item.date
          that.editedItem.confirmado = item.confirmado
          that.editedItem.emailModificado = that.datosUsuario().email_user
          that.editedItem.emailSolicitante = item.emailSolicitante
          that.editedItem.bodega.id = item.bodega.id
          that.editedItem.bodega.nombre_bodega = item.bodega.nombre_bodega
          that.editedItem.tipoVehiculo.id = item.tipoVehiculo.id
          that.editedItem.tipoVehiculo.name = item.tipoVehiculo.name
          that.editedItem.cantidad = parseInt(item.cantidad)          
          that.editedItem.confirmado = item.confirmado
          that.editedItem.emailResponsable = this.datosUsuario().email_user
          that.editedItem.nameResponsable =this.datosUsuario().name_user
          that.editedItem.uidResponsable= this.datosUsuario().uid
          that.editedItem.sistemaResponsable= "warehouse.rayoapp.com"
          that.editedItem.epochResponsable= new Date().getTime()
          

          Vue.nextTick(() => {
          that.dialogTable = true

          })      
      
    },

    deleteItem(item) {
      this.editedItem._id = item._id
      this.editedIndex = this.solicitudes.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
   
      const that = this
      that.editedItem.delete= true
      axios
          .post(
            "https://data.mongodb-api.com/app/warehouse-rayo-imrsd/endpoint/creaSolicitudSupply",
           that.editedItem
          )
          .then(function (response) {
            console.log(response);
          
         
            that.solicitudes.splice(that.editedIndex, 1);
          
            that.saving = false
            that.textoDialogoGenerico =
           'RESPUESTA SERVER: '+ JSON.stringify(response.data) 
            //"Eliminación exitosa" +
            that.dialogoGenerico = true;


//dialogo exito
            

          })
          .catch(function (error) {
            that.saving = false
            //dialogo fallo
            that.dialogoGenerico = true;
            console.log(error);
            that.textoDialogoGenerico = error.data.message;
          });

      

      this.closeDelete();
    },

    close() {
      this.dialogTable = false;
      // this.$nextTick(() => {
    
        this.editedIndex = -1;
 
      this.checaForm()

      // });
 
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        // this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;

        this.editedItem = JSON.parse(JSON.stringify(this.baseEditedItem))
      this.checaForm()
      });
    },
    
  
    save(date) {
      this.$refs.menu.save(date);
      this.editedItem.date = date;
      // this.editedItem = JSON.parse(JSON.stringify(this.baseEditedItem))
      this.checaForm()
    },
    datosUsuario () {
      // console.log(firebase.auth())
      return {
        email_user: firebase.auth().currentUser.email,
        name_user: firebase.auth().currentUser.displayName,
        uid: firebase.auth().currentUser.uid
      }
    },
  },
};
</script>
